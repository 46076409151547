import classNames from "classnames";
import moment from "moment";
import { Icons } from "prefab";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import * as constants from "../../../../constants";
import { CAMPAIGN_STATUS_LIST, STATUS_ICON_COLORS } from "../../../../constants";
import pageStyles from "../../../../styles/App.module.scss";
import ButtonStyle from "../../../../styles/Button.module.scss";
import styles from "../../../../styles/Campaigns/Campaigns.module.scss";
import { bindDispatch } from "../../../../utils";
import Breadcrumb from "../../../common/BreadCrumb";
import PageHeader from "../../../common/PageHeader";
import PageLoadingWithTabs from "../../../common/PageLoadingWithTabs";
import StatusIcon from "../../../common/StatusIcon";
import TabView from "../../../common/Tabs";
import CancelCampaigns from "../bulkActions/CancelCampaigns";
import PauseOrResumeActions from "../bulkActions/PauseOrResumeActions";
import CampaignTree from "../CampaignStructure/CampaignTree";
import ViewFiles from "../common/Files";
import CampaignDetails from "../common/pages/CampaignDetails";
import { isCampaignStatusChangesAvailable } from "../utils";
import CampaignMedia from "./tabs/CampaignMedia";
import CampaignSpotStatus from "./tabs/CampaignSpotStatus";
import CampaignSummary from "./tabs/CampaignSummary";
import CampaignTargetGroups from "./tabs/CampaignTargetGroups";
import CampaignTheatres from "./tabs/CampaignTheatres";

const { CampaignIcon } = Icons;
const _LINKS = [{ name: "PageHeader.campaigns", path: "/campaigns/campaigns" }];

export class CampaignView extends Component {
  state = {
    isCampaignStructureLoading: true,
    campaignStructure: {},
    isCampaignStructureError: false,
    openedBulkAction: null,
  };

  componentDidMount = async () => {
    await this.getCampaign();
    //await this.fetchCampaignTreeData();
  };

  // fetchCampaignTreeData = () => {
  //   const {
  //     match: { params },
  //   } = this.props;

  //   getCampaignTreeApi(params.campaignId)
  //     .then((campaignRes) =>
  //       this.setState({
  //         isCampaignStructureLoading: false,
  //         campaignStructure: campaignRes,
  //       })
  //     )
  //     .catch((err) => {
  //       this.setState({
  //         isCampaignStructureLoading: false,
  //         isCampaignStructureError: true,
  //         campaignStructure: null,
  //       });
  //     });
  // };

  getCampaign = () => {
    const {
      match: { params },
      actions,
    } = this.props;
    if (params.action === "files") {
      actions.getCampaignFiles(params.campaignId);
    }
    actions.getCampaign(params);
  };

  getStatusDetails = (status) => {
    return CAMPAIGN_STATUS_LIST.find((item) => item.id === status);
  };

  closeFile = () => {
    const {
      history,
      actions,
      match: { params },
    } = this.props;
    history.push(`/campaigns/campaigns/${params.campaignId}/${params.tabId}`);
    actions.updateCampaignFiles({ isOpen: false, files: [] });
  };

  viewCampaignFiles = () => {
    const {
      history,
      actions,
      match: { params },
    } = this.props;
    history.push(`/campaigns/campaigns/${params.campaignId}/${params.tabId}/files`);
    actions.viewCampaignFiles([]);
    actions.getCampaignFiles(params.campaignId);
  };

  renderTabWithCampaignStructure = (tabPanel) => {
    //const { isCampaignStructureLoading, campaignStructure, isCampaignStructureError } = this.state;
    const {
      campaigns: { campaign },
    } = this.props;

    // if (isCampaignStructureError) return tabPanel;

    return (
      <div className={classNames(pageStyles.pageContainer, styles.tabContentWrapper)}>
        <div className={styles.tabPanel}>{tabPanel}</div>
        {campaign?.id && (
          <CampaignTree
            // isLoading={isCampaignStructureLoading}
            // campaignTreeData={campaignStructure}
            campaignIdForSummary={campaign.id}
          />
        )}
      </div>
    );
  };

  getCurrentActionPeriod = () => {
    const {
      campaigns: { campaign },
    } = this.props;

    if (campaign.statusChanges.length === 0)
      return {
        from: new Date(),
      };

    return campaign.statusChanges.find(
      (item) =>
        item.status === this.state.openedBulkAction &&
        moment(item.toDate, "YYYY-MM-DD").isAfter(moment.now())
    );
  };

  openBulkAction = (bulkAction) => {
    this.setState({ openedBulkAction: bulkAction });
  };

  closeBulkAction = () => {
    this.setState({ openedBulkAction: null });
  };

  redirectToFirstMediaWithoutContent(targetGroups) {
    const { history } = this.props;
    for (const targetGroup of targetGroups) {
      for (const media of targetGroup.media) {
        // Check if the media doesn't have content or contentId
        if (!media.content || !media.contentId) {
          // Redirect to edit this media
          history.push(`/campaign-create/${media.campaignId}/media/${media.id}`);
          return;
        }
      }
    }
  }

  render = () => {
    const {
      actions,
      history,
      campaigns: { campaign, isLoading, isCampaignFileLoading, campaignFiles },
      match: { params },
      userData,
    } = this.props;
    const { openedBulkAction } = this.state;
    const { CAMPAIGN_ACTIONS, CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST, CAMPAIGN_TYPE } = constants;

    if (isLoading) {
      return <PageLoadingWithTabs />;
    }

    const statusDetails = this.getStatusDetails(campaign.status);
    const contenAvailabilityStatus = campaign.isContentAvailable;
    //isPaused is the boolean that controls if rightHandPanel is opened for pausing or resuming the campaign
    const isPaused = openedBulkAction === CAMPAIGN_ACTIONS.PAUSE;
    //isEdit: checks for all selected campaigns if any campaign has openedBukAction previosuly defiened within its statusChanges array with toDate > today
    const isEdit = isCampaignStatusChangesAvailable(
      [campaign],
      //status related to currently openedBulkAction passed
      isPaused ? constants.CAMPAIGN_STATUSES.PAUSED : constants.CAMPAIGN_STATUSES.ACTIVE
    );
    const campaignHeader =
      isEdit && isPaused
        ? "RightPanelHeader.editPauseCampaign"
        : isEdit
        ? "RightPanelHeader.editResumeCampaign"
        : isPaused
        ? "RightPanelHeader.pauseCampaign"
        : "RightPanelHeader.resumeCampaign";
    return (
      <div className="col-12 clearfix">
        <div className="col-12 clearfix">
          <Breadcrumb
            isLoading={isLoading}
            history={history}
            links={[..._LINKS, { name: `${campaign.name}` }]}
          />
        </div>
        <PageHeader
          name={`${campaign.name}`}
          headerIcon={<CampaignIcon />}
          customBadge={
            statusDetails && (
              <>
                <div className={`${styles.statusBadge}`}>
                  <StatusIcon color={statusDetails.color} />
                  <span>{statusDetails.displayName}</span>
                </div>
                <div className={`${styles.statusBadge} `}>
                  <StatusIcon
                    color={
                      contenAvailabilityStatus ? STATUS_ICON_COLORS.GREEN : STATUS_ICON_COLORS.RED
                    }
                    type={constants.STATUS_ICON_TYPES.CONTENT_UNAVAILABLE}
                  />
                  <span>
                    {contenAvailabilityStatus ? "Content Available" : "Content Unavailable"}
                  </span>
                </div>
              </>
            )
          }
          buttons={[
            {
              text: "Button.edit",
              onClick: () => history.push(`/campaign-create/${campaign.id}/details`),
              className: ButtonStyle.buttonSpacing,
              iconName: "EditIcon",
              isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(
                campaign.status
              ),
              checkScope: true,
              scope: constants.SCOPES.CAMPAIGNS,
              scopeAction: constants.SCOPE_ACTIONS.WRITE,
              userData,
            },
            {
              text: "Button.cancel",
              onClick: () => this.openBulkAction(CAMPAIGN_ACTIONS.CANCEL),
              className: ButtonStyle.buttonSpacing,
              iconName: "CancelIcon",
              isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.CANCEL].includes(
                campaign.status
              ),
              checkScope: true,
              scope: constants.SCOPES.CAMPAIGNS,
              scopeAction: constants.SCOPE_ACTIONS.CANCEL,
              userData,
            },
            {
              text: "Button.pause",
              onClick: () => this.openBulkAction(CAMPAIGN_ACTIONS.PAUSE),
              className: `${ButtonStyle.buttonSpacing} `,
              iconName: "PauseIcon",
              isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.PAUSE].includes(
                campaign.status
              ),
              checkScope: true,
              scope: constants.SCOPES.CAMPAIGNS,
              scopeAction: constants.SCOPE_ACTIONS.WRITE,
              userData,
            },
            {
              text: "Button.resume",
              onClick: () => this.openBulkAction(CAMPAIGN_ACTIONS.RESUME),
              className: ButtonStyle.buttonSpacing,
              iconName: "PlayIcon",
              isHidden: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.RESUME].includes(
                campaign.status
              ),
              checkScope: true,
              scope: constants.SCOPES.CAMPAIGNS,
              scopeAction: constants.SCOPE_ACTIONS.WRITE,
              userData,
            },
          ]}
          renderRightSideComponent={() => (
            <>
              {/* <LocalizedButton
                scope={SCOPES.CAMPAIGNS}
                scopeAction={SCOPE_ACTIONS.READ}
                userData={userData}
                text="Button.viewFiles"
                buttonType={BUTTON_TYPE.PRIMARY}
                iconName="ViewIcon"
                className="editButton"
                onClick={this.viewCampaignFiles}
              /> */}
              {/* <button
                className={`${styles.availibilityBtn} ${getCampaignColorClassName(
                  contenAvailabilityStatus ? STATUS_ICON_COLORS.GREEN : STATUS_ICON_COLORS.RED
                )}`}
                style={{ border: "none" }}
                onClick={() =>
                  this.redirectToFirstMediaWithoutContent(campaignStructure.targetGroups)
                }
              >
                {contenAvailabilityStatus ? "Content Available" : "Content Unavailable"}
              </button> */}
            </>
          )}
          isLoading={isLoading}
        />
        <div className={`${styles.tabsContainer}`}>
          <TabView
            id="campaignTabs"
            selectedTabId={params.tabId}
            defaultSelectedTabId="details"
            tabs={[
              {
                id: "summary",
                title: "Summary",
                goto: `/campaigns/campaigns/${campaign.id}/summary`,
                panel: this.renderTabWithCampaignStructure(
                  <CampaignSummary campaignId={campaign.id} />
                ),
              },
              {
                id: "details",
                title: "Details",
                goto: `/campaigns/campaigns/${campaign.id}/details`,
                panel: this.renderTabWithCampaignStructure(
                  <CampaignDetails
                    campaign={campaign}
                    userData={userData}
                    params={params}
                    showCampaignActions
                  />
                ),
              },
              {
                id: "target_groups",
                title: "Target Group",
                goto: `/campaigns/campaigns/${campaign.id}/target_groups`,
                panel: <CampaignTargetGroups />,
              },
              {
                id: "media",
                title: "Media",
                goto: `/campaigns/campaigns/${campaign.id}/media`,
                panel: <CampaignMedia />,
              },
              {
                id: "theatres",
                title: "Theatres",
                goto: `/campaigns/campaigns/${campaign.id}/theatres/theatres`,
                panel: <CampaignTheatres />,
              },
              {
                id: "spot_status",
                title: "Spot Status",
                goto: `/campaigns/campaigns/${campaign.id}/spot_status`,
                panel: <CampaignSpotStatus />,
              },
            ]}
          />
        </div>
        {(openedBulkAction === CAMPAIGN_ACTIONS.RESUME ||
          openedBulkAction === CAMPAIGN_ACTIONS.PAUSE) && (
          <PauseOrResumeActions
            header={campaignHeader}
            period={this.getCurrentActionPeriod()}
            type={openedBulkAction}
            isPaused={isPaused}
            // showEdit={campaign.status !== CAMPAIGN_STATUSES.CONSUMED}
            showEdit={true}
            selected={[campaign]}
            isEdit={isEdit}
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            history={history}
            onPauseOrResumeAction={(period) => {
              this.props.actions.pauseOrResumeCampaigns(
                [campaign.id],
                openedBulkAction,
                period,
                null,
                true
              );
            }}
          />
        )}
        {openedBulkAction === CAMPAIGN_ACTIONS.CANCEL && (
          <CancelCampaigns
            selectionInfo={[]}
            header="RightPanelHeader.cancelCampaign"
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            cancelCampaigns={(data) => {
              this.props.actions.cancelCampaigns(
                { ...data, ids: [campaign.id] },
                CAMPAIGN_TYPE.CAMPAIGN,
                null,
                this.closeBulkAction,
                true
              );
            }}
          />
        )}
        <ViewFiles
          campaignId={campaign.id}
          isLoading={isCampaignFileLoading}
          isOpen={campaignFiles.isOpen}
          files={campaignFiles.files}
          onClose={this.closeFile}
          downloadFile={actions.downloadCampaignFile}
        />
      </div>
    );
  };
}
const mapStateToProps = createSelector(
  (state) => state.campaigns,
  (state) => state.userData,
  (state) => state.media,
  (campaigns, userData, media) => ({ campaigns, userData: userData.user, media })
);

export default connect(mapStateToProps, bindDispatch)(CampaignView);
